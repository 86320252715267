import React, { useEffect } from 'react'
import { ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline'
import PointsAlert from 'features/player/components/Player/components/User/components/Session/components/PointsAlert'
import { useTranslation } from 'next-i18next'
import { Fade } from 'react-awesome-reveal'
import { useFirestore, useFirestoreDocData } from 'reactfire'

import Logo from 'components/Logo'
import ThemeStyle from 'components/ThemeStyle'
import getCdnUrl from 'utils/getCdnUrl'

import NameForm from './components/NameForm'
import Presentation from './components/Presentation'
import UserFieldsForm from './components/UserFieldsForm'

export default function Session({ sessionId, uid }) {
  const { t } = useTranslation()
  const firestore = useFirestore()
  const sessionRef = firestore.collection('sessions').doc(sessionId)
  const { data: session } = useFirestoreDocData(sessionRef)
  const sessionUserRef = firestore
    .collection('sessions')
    .doc(sessionId)
    .collection('users')
    .doc(uid)

  const { data: sessionUser } = useFirestoreDocData(sessionUserRef)
  const name = sessionUser?.name
  const fields = sessionUser?.fields
  const presentationId = session?.presentationId
  const points = sessionUser?.points || 0
  const presentationRef = firestore
    .collection('presentations')
    .doc(presentationId)
  const { data: presentation } = useFirestoreDocData(presentationRef)
  const isGame = presentation?.questionSlidesCount > 0

  const themeId = presentation
    ? presentation?.settings?.theme || 'default'
    : 'undefined'
  const themeRef = firestore.collection('themes').doc(themeId)

  const { data: theme } = useFirestoreDocData(themeRef, {
    idField: 'id',
  })

  useEffect(() => {
    document.body.className = `custom-theme-${theme?.id}`

    return () => {
      document.body.className = ''
    }
  }, [theme])

  const slideId = session?.slideId

  const slideRef = firestore
    .collection('presentations')
    .doc(presentationId)
    .collection('slides')
    .doc(slideId)

  const { data: slide } = useFirestoreDocData(slideRef)

  const handleLogout = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(t('common.confirmExit'))) {
      firestore.collection('players').doc(uid).update({
        sessionId: null,
      })
    }
  }

  const userFields = presentation?.settings?.userFields
  const hasUserFields =
    userFields?.name ||
    userFields?.email ||
    userFields?.phone ||
    userFields?.customFields?.length > 0

  if (hasUserFields && !fields && theme) {
    return (
      <div
        className={`flex h-full flex-col justify-center p-4 custom-theme-${theme?.id}`}
        style={{
          backgroundImage: `url("${getCdnUrl(slide?.background?.url)}")`,
        }}
      >
        <ThemeStyle theme={theme} key={theme?.id} />
        <div className="mx-auto flex h-full w-full max-w-xl flex-col">
          <div className="flex h-1/3 items-center justify-center">
            <Logo theme={theme} />
          </div>
          <Fade className="flex h-1/3 flex-col justify-center">
            <UserFieldsForm
              userFields={userFields}
              sessionId={sessionId}
              uid={uid}
            />
          </Fade>
        </div>
      </div>
    )
  }

  if (!hasUserFields && isGame && !name && theme) {
    return (
      <div
        className={`flex h-full flex-col justify-center p-4 custom-theme-${theme?.id}`}
      >
        <ThemeStyle theme={theme} key={theme?.id} />
        <div className="mx-auto flex h-full w-full max-w-xl flex-col">
          <div className="flex h-1/3 items-center justify-center">
            <Logo theme={theme} />
          </div>
          <Fade className="flex h-1/3 flex-col justify-center">
            <NameForm sessionId={sessionId} uid={uid} />
          </Fade>
        </div>
      </div>
    )
  }

  return (
    <div
      className="flex h-full flex-col bg-cover bg-center"
      style={{
        backgroundImage: `url("${getCdnUrl(slide?.background?.url)}")`,
      }}
    >
      <ThemeStyle theme={theme} key={theme?.id} />
      {isGame && (
        <header
          id="header"
          className="bg-fade flex items-center justify-between p-4 backdrop-blur-sm"
        >
          <div className="flex flex-col items-center">
            <div
              className={`max-w-[40vw] font-medium ${
                !theme?.images?.logo?.url
                  ? 'origin-left scale-75 md:transform-none'
                  : ''
              }`}
            >
              <Logo theme={theme} />
            </div>
          </div>
          <div className="ml-8 flex flex-col items-end">
            <div className="ml-4 flex items-center text-right">
              <div className="font-medium">{name}</div>
              <div className="bg-success ml-2 rounded-md px-2 font-medium text-white">
                {points}
              </div>
              <button type="button" className="ml-2" onClick={handleLogout}>
                <ArrowLeftOnRectangleIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
        </header>
      )}
      {!isGame && (
        <header
          id="header"
          className="bg-fade flex items-start justify-between p-4"
        >
          <div className="flex flex-col items-center">
            <div className="font-medium">
              <Logo theme={theme} />
            </div>
          </div>
          <div>
            <button type="button" className="ml-2" onClick={handleLogout}>
              <ArrowLeftOnRectangleIcon className="h-5 w-5" />
            </button>
          </div>
        </header>
      )}
      <div className="flex-1">
        {presentationId && (
          <Presentation
            session={session}
            sessionId={sessionId}
            sessionRef={sessionRef}
            sessionUser={sessionUser}
            presentation={presentation}
            presentationId={presentationId}
            uid={uid}
            theme={theme}
          />
        )}
      </div>
      {isGame && <PointsAlert points={points} />}
    </div>
  )
}
