import { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Fade } from 'react-awesome-reveal'
import { DotLoader as Loader } from 'react-spinners'
import { useFirestore, useFirestoreDocData } from 'reactfire'

import Finish from './components/Finish'
import Points from './components/Points'
import Slide from './components/Slide'

export default function Presentation({
  presentation,
  presentationId,
  session,
  sessionId,
  sessionRef,
  sessionUser,
  uid,
  theme,
}) {
  const [screenHeight, setScreenHeight] = useState(0)
  const { t } = useTranslation()
  const firestore = useFirestore()

  const presentationRef = firestore
    .collection('presentations')
    .doc(presentationId)

  const slideId = session?.slideId
  const isFinished = session?.isFinished

  const isGame = presentation?.questionSlidesCount > 0
  const slideRef = presentationRef.collection('slides').doc(slideId)

  const { data: slide } = useFirestoreDocData(slideRef)

  useEffect(() => {
    if (session?.isFinished) {
      firestore.collection('players').doc(uid).update({
        sessionId: null,
      })
    }
  }, [])

  // Calculate screen height on resize
  useEffect(() => {
    const handleResize = () => {
      const headerHeight = document.getElementById('header')?.clientHeight || 0
      const footerHeight = document.getElementById('footer')?.clientHeight || 0
      const windowHeight = window.innerHeight

      setScreenHeight(windowHeight - headerHeight - footerHeight)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (!slide) {
    return (
      <Fade className="flex h-full flex-col justify-center p-4 font-medium">
        <div>
          <h1 className="mb-4 text-center text-xl">
            {t('presentation.begin.soon')}
          </h1>
          <div className="text-primary flex justify-center">
            <Loader size={80} color="currentColor" />
          </div>
        </div>
      </Fade>
    )
  }

  if (isFinished) {
    if (isGame) {
      return <Points sessionUser={sessionUser} />
    }
    return <Finish session={session} />
  }

  return (
    <Fade
      className="slide-content h-full overflow-y-scroll break-words p-4"
      style={{ height: screenHeight }}
    >
      <div className="h-full">
        <Slide
          key={slideId}
          presentation={presentation}
          presentationId={presentationId}
          sessionId={sessionId}
          sessionRef={sessionRef}
          slideId={slideId}
          uid={uid}
          theme={theme}
        />
      </div>
    </Fade>
  )
}
